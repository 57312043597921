import React, { useRef, useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Box,
  Container,
  Center,
  Heading,
  UnorderedList,
  ListItem,
  Flex,
  Spacer,
  useMediaQuery,
} from "@chakra-ui/react";

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const Section = (props) => {
  const { items, title, sectionRef, isPage, hash } = props;
  const ref = useRef([]);
  const [visibleSection, setVisibleSection] = useState();
  const [hashScrolled, setHashScrolled] = useState(false);
  const [isBig] = useMediaQuery("(min-width: 1024px)");

  useEffect(() => {
    if (hash && !hashScrolled) {
      const hashRef = hash.replace("#", "").replaceAll("-", " ");
      const index = items.findIndex(
        (item) => item.title.toLowerCase() === hashRef
      );
      index > 0 &&
        ref.current[index].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

      setHashScrolled(true);
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY + 200;

      const selected = ref.current.findIndex((ele) => {
        if (!ele) return null;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return null;
      });

      if (selected && selected !== visibleSection) {
        setVisibleSection(selected);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hash, items, visibleSection, hashScrolled]);

  if (isPage) {
    return (
      items &&
      items.length > 0 && (
        <>
          <Flex>
            <Box width="100px" />
            <Spacer />
            <Container maxWidth="7xl" paddingTop="150px">
              <Heading size="lg" color="gray" marginBottom="1em" align="center">
                {title}
              </Heading>

              {items.map((item, index) => {
                return (
                  <Container
                    key={`item-${index}`}
                    maxWidth="5xl"
                    borderWidth="1px"
                    borderRadius="lg"
                    p="5vh"
                    ref={(el) => (ref.current[index] = el)}
                    fontFamily="Noto sans, sans-serif"
                    marginBottom="1em"
                  >
                    <Heading size="md">{item.title}</Heading>
                    <>{item.text}</>
                  </Container>
                );
              })}
            </Container>
            {isBig && (
              <>
                <Spacer />
                <Box minWidth="300px" />
              </>
            )}
          </Flex>
          {isBig && (
            <Container maxWidth="xs" position="fixed" right="0px" top="100px">
              <b>ON THIS PAGE</b>
              <UnorderedList marginLeft="30px" listStyleType="none">
                {items.map((item, index) => {
                  return (
                    <ListItem
                      onClick={() =>
                        ref.current[index].scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        })
                      }
                      cursor="pointer"
                      _hover={{ textDecoration: "underline" }}
                      key={`contents-${index}`}
                    >
                      {visibleSection === index ? (
                        <b>{item.title}</b>
                      ) : (
                        item.title
                      )}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </Container>
          )}
        </>
      )
    );
  }

  return (
    items &&
    items.length > 0 && (
      <Center>
        <Container
          maxW="7xl"
          borderWidth="1px"
          borderRadius="lg"
          p="10vh"
          ref={sectionRef}
          fontFamily="Noto sans, sans-serif"
        >
          <Heading size="lg" color="gray" marginBottom="1em">
            {title}
          </Heading>

          <Accordion allowToggle>
            {items.map((item, index) => {
              return (
                <AccordionItem key={`${title}-${index}`}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {item.title}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{item.text}</AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Container>
      </Center>
    )
  );
};

export default Section;
