import React from "react";

import Layout from "../components/Layout";
import DataSection from "../content/DataSection";

const Data = (props) => {
  return (
    <Layout>
      <DataSection isPage />
    </Layout>
  );
};

export default Data;
